import React, { FC } from "react";
import {
  ServiceCard,
  ServiceCardProps,
} from "../../components/cards/service-card/service-card";
import { Spin } from "antd";
import { Container } from "../../components/container/container";
import { Carousel } from "../../components/carousel/carousel";
import { SectionTitle } from "../../components/section-title/section-title";

import "./relevant-services.css";

export interface RelevantServicesProps {
  services?: ServiceCardProps[];
}

export const RelevantServices: FC<RelevantServicesProps> = ({ services }) => {
  return (
    <div className="xvia-top-services">
      <Container className="xvia-relevant-services__container">
        <SectionTitle
          text={"Acesso rápido"}
          linkText={"Ver tudo"}
          linkUrl={`/app/catalog/search/acesso-rapido`}
        />
        <Carousel>
          {!!services &&
            services?.map((service) => {
              return (
                <ServiceCard
                  title={service.title}
                  description={service.description}
                  categoryIcon={service?.categoryIcon}
                  onClick={service.onClick}
                  onClickFavourite={service.onClickFavourite}
                  rating={service?.rating}
                  type={service?.type}
                  imageUrl={service?.imageUrl}
                  targets={service?.targets}
                  accessProfile={service?.accessProfile}
                />
              );
            })}
        </Carousel>
        {!services && (
          <div className={"xvia-relevant-services__loading"}>
            <Spin />
          </div>
        )}
      </Container>
    </div>
  );
};
